<template>
    <Toast />
    <div id="container" class="container">
        <div class="db-header">
            <h1>Text Request Offboarding</h1>
        </div>
        <div class="mainSection">
            <div class="section">
                <div class="label-text-group center">
                    <label class="label"><b>Email:</b></label>
                    <InputText class="inputtext" type="text" v-model="this.email" />
                </div>
            </div>
            <div class="section">
                <div class="label-text-group center flex flex-column gap-2">
                    <label class="label"><b>Alt Email (optional):</b></label>
                    <InputText class="inputtext" type="text" v-model="this.altEmail" aria-describedby="altEmail-help" />
                </div>
                <div>
                    <small id="altEmail-help">The email to assign the messages to if different from email above.</small>
                </div>
            </div>
            <div class="section">
                <div class="label-text-group center flex flex-column gap-2">
                    <label class="label"><b>Start Date (optional):</b></label>
                    <Calendar class="inputtext" type="text" v-model="this.startDate" />
                </div>
            </div>
            <div class="section">
                <div class="label-text-group center flex flex-column gap-2">
                    <label class="label"><b>End Date (optional):</b></label>
                    <Calendar class="inputtext" type="text" v-model="this.endDate" />
                </div>
            </div>
            <div class="center">
                <Button class="button" label="Submit" @click="this.offBoard()" />
                <Button class="button" label="Delete" @click="this.deleteData($event)" outlined severity="danger" />
                <ConfirmPopup></ConfirmPopup>
            </div>
            <div id="spinner" class="spinner">
                <ProgressSpinner />
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { TextRequestRepository } from "@/repositories/textRequestRepository";

export default {
    name: 'Dashboard',
    data() {
        return {
            toast: useToast(),
            confirm: useConfirm(),
            email: undefined,
            altEmail: undefined,
            startDate: undefined,
            endDate: undefined,
            isBusy: false
        }
    },
    created() {
        this.$emitter.on("login", () => {
            this.showContainer();
        }),
            this.$emitter.on("logout", () => {
                this.hideContainer();
            })
    },
    mounted() {
        this.hideSpinner();
        this.hideContainer();
    },
    methods: {
        hideSpinner() {
            var spinner = document.getElementById("spinner");
            spinner.style.display = "none";
        },
        showSpinner() {
            var spinner = document.getElementById("spinner");
            spinner.style.display = "block";
        },
        hideContainer() {
            var container = document.getElementById("container");
            container.style.display = "none";
        },
        showContainer() {
            var container = document.getElementById("container");
            container.style.display = "block";
        },
        async deleteData(event) {
            if (this.isBusy) {
                this.toast.add({ severity: 'info', summary: 'Busy', detail: 'Already running.'});
                return;
            }

            if (this.email === '' || this.email === undefined) {
                this.toast.add({ severity: 'error', summary: 'Missing', detail: 'Email is missing.' });
                return;
            }

            this.confirm.require({
                target: event.currentTarget,
                message: "Are you sure you want to delete all blobs and messages for " + this.email + "?",
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    try {
                        this.isBusy = true;
                        var service = new TextRequestRepository();
                        this.showSpinner();
                        await service.delete(this.email);
                        this.toast.add({ severity: 'info', summary: 'Completed', detail: 'Messages deleted.'});
                    }
                    catch (err) {
                        this.toast.add({ severity: 'error', summary: 'Error', detail: err, life: 5000 });
                    }
                    finally {
                        this.hideSpinner();
                        this.isBusy = false;
                    }
                },
                reject: () => {
                    this.toast.add({ severity: 'error', summary: 'Cancelled', detail: 'Delete cancelled.'});
                }
            });
        },
        async offBoard() {
            if (this.isBusy) {
                this.toast.add({ severity: 'info', summary: 'Busy', detail: 'Already running.' });
                return;
            }

            if (this.email === '' || this.email === undefined) {
                this.toast.add({ severity: 'error', summary: 'Missing', detail: 'Email is missing.'});
                return;
            }

            try {
                this.isBusy = true;
                var service = new TextRequestRepository();
                this.showSpinner();
                
                var requester = this.$store.account.username;
                var result = await service.offboardUser(this.email, requester, this.altEmail, this.startDate, this.endDate);
                
                if (result != undefined) {
                    this.toast.add({ severity: 'info', summary: 'Completed', detail: result});
                }
            }
            catch (err) {
                console.log(err);

                let error;

                if (err.response.data.status === undefined){
                    error = err.response.data;
                }
                else {
                    error = err.response.data.status + '-' + err.response.data.title;
                }

                this.toast.add({ severity: 'error', summary: 'Error', detail: error });
            }
            finally {
                this.isBusy = false;
                this.hideSpinner();
            }
        }
    }
}
</script>

<style scoped>
.spinner {
    margin-top: 75px;
    text-align: center;
}

.db-header {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
}

.container {
    margin-top: 75px;
}

.inputtext {
    width: 300px;
}

.button {
    margin: 10px;
}
</style>
