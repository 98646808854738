import BaseRepository from "@/repositories/baseRepository.js";

export class TextRequestRepository extends BaseRepository {
    constructor() {
        super();
    }

    async offboardUser(email, requester, altEmail, startDate, endDate){
        var params = new Object();
        params.emailAddress = email;
        params.altEmailAddress = altEmail;
        params.startDate = startDate;
        params.endDate = endDate;
        params.requester = requester;

        return await super.postWithBody("/api/textrequest/offboard", params)
    }

    async delete(email){
        var params = new Object();
        params.emailAddress = email;

        return await super.delete("/api/textrequest/delete", params)
    }
}