import axios from "axios";
import jwtdecode from "jwt-decode";
import store from "@/store/store.js";

export default class BaseRepository {
  static baseUrl = process.env.VUE_APP_API_URL;
  constructor() {}

  async postWithBody(pathSegment, params, retry = false) {
    var url = BaseRepository.baseUrl + pathSegment;
    let returnResponse;
    var token = await this.getToken();

    await axios({
      method: "POST",
      url: url,
      data: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        returnResponse = response.data;
      })
      .catch(async function (error) {
        if (error.response.status === 401) {
          //set token and try again
          store.commit("setApiToken", undefined);
          store.commit("setApiTokenExp", undefined);

          //if 401, try again
          if (!retry) {
            return await this.postWithBody(pathSegment, params, true);
          }
        }
        throw error;
      });

    return returnResponse;
  }

  async put(pathSegment, retry = false) {
    var url = BaseRepository.baseUrl + pathSegment;
    let returnResponse;
    var token = await this.getToken();

    await axios({
      method: "PUT",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        returnResponse = response.data;
      })
      .catch(async function (error) {
        if (error.response.status === 401) {
          //set token and try again
          store.commit("setApiToken", "");
          store.commit("setApiTokenExp", "");

          //if 401, try again
          if (!retry) {
            return await this.put(pathSegment, true);
          }
        }
        throw error;
      });
    return returnResponse;
  }

  async get(pathSegment, parameters, retry = false) {
    var url = BaseRepository.baseUrl + pathSegment;

    if (parameters) {
      parameters.forEach((element) => (url += element + "/"));
    }

    let returnResponse;
    var token = await this.getToken();

    await axios({
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        returnResponse = response.data;
      })
      .catch(async function (error) {
        if (error.response.status === 401) {
          //set token and try again
          store.commit("setApiToken", "");
          store.commit("setApiTokenExp", "");

          //if 401, try again
          if (!retry) {
            return await this.get(pathSegment, parameters, true);
          }
        }
        throw error;
      });

    return returnResponse;
  }
  
  async delete(pathSegment, params, retry = false) {
    var url = BaseRepository.baseUrl + pathSegment;
    let returnResponse;
    var token = await this.getToken();

    await axios({
      method: "DELETE",
      url: url,
      data: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        returnResponse = response.data;
      })
      .catch(async function (error) {
        if (error.response.status === 401) {
          //set token and try again
          store.commit("setApiToken", "");
          store.commit("setApiTokenExp", "");

          //if 401, try again
          if (!retry) {
            return await this.delete(pathSegment, params, true);
          }
        }
        throw error;
      });
    return returnResponse;
  }

  async getWithBody(pathSegment, params, retry = false) {
    var url = BaseRepository.baseUrl + pathSegment;
    let returnResponse;
    var token = await this.getToken();

    await axios({
      method: "GET",
      url: url,
      data: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        returnResponse = response.data;
      })
      .catch(async function (error) {
        if (error.response.status === 401) {
          //set token and try again
          store.commit("setApiToken", "");
          store.commit("setApiTokenExp", "");

          //if 401, try again
          if (!retry) {
            return await this.getWithBody(pathSegment, params, true);
          }
        }
        throw error;
      });

    return returnResponse;
  }

  async getToken() {
    if (this.checkApiToken()) {
      return store.getters.apiToken;
    }

    var url = BaseRepository.baseUrl + "/api/Authentication/login";
    var login = new Object();
    login.adToken = store.getters.accessToken;
    let returnResponse;
    let successful = false;

    await axios({
      method: "POST",
      url: url,
      data: JSON.stringify(login),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        returnResponse = response.data;
        successful = true;
      })
      .catch(function (error) {
        console.log(error);
      });

    if (successful) {
      this.parseJwt(returnResponse);
    }

    return returnResponse;
  }

  parseJwt(token) {
    var issuedBy = process.env.VUE_APP_JWT_ISS;
    var audience = process.env.VUE_APP_JWT_AUD;
    var decodedToken = jwtdecode(token);

    if (issuedBy !== decodedToken.iss || audience !== decodedToken.aud) {
      throw new Error("Invalid token.");
    }

    store.commit("setApiToken", token);
    store.commit("setApiTokenExp", decodedToken.exp);
  }

  checkApiToken() {
    var exp = store.getters.apiTokenExp;
    var token = store.getters.apiToken;

    if (token === "" || exp === null || exp === "undefined") {
     // console.log("token is null");
      return false;
    }

    const currentDate = new Date();
    const year = currentDate.getUTCFullYear();
    const month = currentDate.getUTCMonth();
    const day = currentDate.getUTCDate();
    const hours = currentDate.getUTCHours();
    const minutes = currentDate.getUTCMinutes();
    const seconds = currentDate.getUTCSeconds();
    const milliseconds = currentDate.getUTCMilliseconds();
    const utcTimeInMs = Date.UTC(
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    );

    if (utcTimeInMs > exp * 1000) {
      //console.log("token is expired");
      return false;
    }

    //console.log("token is ok");
    return true;
  }
}
