import { createStore } from "vuex";
import { BrowserCacheLocation } from "@azure/msal-browser";

const store = createStore({
    state() {
      return {
        msalConfig: {
          auth: {
            clientId: "edc51497-115b-487d-924a-572d41c16364",
            redirectUri: "",
            postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_URI,
            authority:
              "https://login.microsoftonline.com/a3426403-026c-4ede-a083-96586ce500c0",
          },
          cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
          },
        },
        accessToken: "",
        account: undefined,
        apiToken: "",
        apiTokenExp: ""
      };
    },
    mutations: {
      setAccessToken(state, token) {
        state.accessToken = token;
      },
      setAccount(state, account) {
        state.account = account;
      },
      setApiToken(state, apiToken) {
        state.apiToken = apiToken;
      },
      setApiTokenExp(state, apiTokenExp) {
        state.apiTokenExp = apiTokenExp;
      }
    },
    getters: {
      account: (state) => {
        return state.account;
      },
      accessToken: (state) => {
        return state.accessToken;
      },
      apiToken: (state) => {
        return state.apiToken;
      },
      apiTokenExp: (state) => {
        return state.apiTokenExp;
      }
    },
    actions: {
      signout({state}){
        console.log("signout called in store");
        state.accessToken = "";
        state.account = undefined;
        state.apiToken = "";
        state.apiTokenExp = "";
      }
    }
  });
  
  export default store;